body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
}

.map-container {
    top: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -10;
}

.leaflet-container {
    height: 100%;
    width: 100%;
}

