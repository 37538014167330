@media only screen and (max-width: 900px) {
   #ErrorPage {
	width: 100vw;
	height: 100vh;
	padding-top: 50px;
	padding: 25px;
   }
   

    h1 {
	font-size: 5em;
    }

    h3 {
	font-size: .8em;
    }
}

@media only screen and (min-width: 900px) {
    #ErrorPage {
	width: 100vw;
	height: 100vh;
	padding: 100px;
    }

    h1 {
	font-size: 8em;
    }
}
