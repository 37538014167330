.RouteContainer {

}

.RouteContainer-main {
    /* shift over for the side bar */
    margin-left: 250px;
}

.RouteContainer-header {
    z-index: 10;
}

.RouteContainer-header-margin {
}

.RouteContainer-h1 {
    font-size: 2.5em;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 110px;
    text-align: left;
    z-index: 5000;
}
