.Bus {

}

.Bus-table {
    
}

.Bus-header {
    font-weight: bold;
}
