.FirstRunHint {
    margin-top: 25px;
    margin-left: 125px;
    padding: 10px;
    display: block;
    max-width: 450px;
    background: #eee;
    border-radius: 15px;
    border-width: 2px;
    border-style: solid;
    border-color: #000;
}
